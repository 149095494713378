import React, { useState } from "react";
import { Formik, Form } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as yup from "yup";
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const CourseForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const areas = [
    { value: "SCIENTIFIC EXPLORATION", label: "SCIENTIFIC EXPLORATION" },
    { value: "ROBOTICS AND CODING", label: "ROBOTICS AND CODING" },
    { value: "ENTREPRENEURSHIP", label: "ENTREPRENEURSHIP" },
    { value: "CREATIVE DESIGN", label: "CREATIVE DESIGN" },
  ];

  const group = [
    { value: "1", label: "4-6 Years" },
    { value: "2", label: "7-9 Years" },
    { value: "3", label: "10-15 Years" },
  ];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  // Time scheduling state
  const [timeRanges, setTimeRanges] = useState(
    Array(7).fill({ start: "", end: "" })
  );
  const [data, setData] = useState([]);

  // Days of the week mapped to their respective indices
  const daysOfWeek = [
    { label: "Sun", value: 0 },
    { label: "Mon", value: 1 },
    { label: "Tue", value: 2 },
    { label: "Wed", value: 3 },
    { label: "Thu", value: 4 },
    { label: "Fri", value: 5 },
    { label: "Sat", value: 6 },
  ];

  const handleTimeChange = (index, field, value) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index][field] = value;
    setTimeRanges(newTimeRanges);
  };

  const handleDayChange = (event, setFieldValue) => {
    const { value, checked } = event.target; // `value` is now the index as a string
    const dayIndex = parseInt(value, 10); // Convert value back to an integer
    let updatedDays = [...data]; // Local state for managing selected days

    if (checked) {
      updatedDays.push(dayIndex); // Add the index if checked
      // Initialize time for the new day added
      const newTimeRanges = [...timeRanges];
      newTimeRanges[dayIndex] = { start: "", end: "" }; // Reset start and end times
      setTimeRanges(newTimeRanges); // Update timeRanges state
    } else {
      updatedDays = updatedDays.filter((d) => d !== dayIndex); // Remove the index if unchecked
    }

    // Sort days based on their indices
    updatedDays.sort((a, b) => a - b); // Sort the indices

    setFieldValue("days", updatedDays); // Update Formik's field with indices
    setData(updatedDays); // Update local state
  };

  const handleFormSubmit = async (values, { resetForm, setErrors }) => {
    const formattedData = {
      ...values,
      price: Number(values.price),
      group: Number(values.group),
      time: JSON.stringify(values.time),
      days: JSON.stringify(values.days),
    };

    // Validate time ranges for selected days
    const timeErrors = {};

    values.days.forEach((dayIndex) => {
      const { start, end } = timeRanges[dayIndex];
      if (!start) {
        timeErrors[`days[${dayIndex}].start`] = "Start time is required";
      }
      if (!end) {
        timeErrors[`days[${dayIndex}].end`] = "End time is required";
      }
      if (start && end && end <= start) {
        timeErrors[`days[${dayIndex}].end`] =
          "End time must be greater than start time";
      }
    });

    // Set the time errors if any
    if (Object.keys(timeErrors).length > 0) {
      setErrors(timeErrors);
      return;
    }

    try {
      await axios.post(`${apiURL}/add-course`, formattedData, {
        withCredentials: true,
      });
      setSnackbarMessage("Event added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      resetForm(); // Reset the form fields to initial values
    } catch (e) {
      console.log(e);
      setSnackbarMessage("Error adding event. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const checkoutSchema = yup.object().shape({
    area: yup.string().required("Area is required"),
    courseName: yup.string().required("Course is required"),
    group: yup.string().required("Age group is required"),
    instructor: yup.string().required("Instructor is required"),
    price: yup
      .number()
      .required("Price is required")
      .positive("Price must be a positive number")
      .typeError("Price must be a number"),
    start: yup
      .date()
      .required("Start date is required")
      .min(new Date(), "Start date cannot be in the past"),
    end: yup
      .date()
      .required("End date is required")
      .min(yup.ref("start"), "End date must be after start date"),
    days: yup
      .array()
      .of(yup.number()) // Assuming the days are stored as numbers
      .min(1, "At least one day must be selected"),
  });

  return (
    <>
      <Typography variant="h2" component="h2" align="center" mb="10px">
        Add Course
      </Typography>
      <Formik
        initialValues={{
          courseName: "",
          area: "",
          group: "",
          instructor: "",
          days: [],
          start: "",
          end: "",
          time: [],
          price: "",
        }}
        onSubmit={(values, formikBag) => {
          handleFormSubmit(values, formikBag); // Pass the formikBag
        }}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex" }}>
              {/* LEFT COLUMN */}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                width="50%"
                marginRight="5%"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Course Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.courseName}
                  name="courseName"
                  error={!!touched.courseName && !!errors.courseName}
                  helperText={touched.courseName && errors.courseName}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{ gridColumn: "span 4" }}
                  error={!!touched.area && !!errors.area}
                >
                  <InputLabel>Area</InputLabel>
                  <Select
                    name="area"
                    value={values.area}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Area"
                  >
                    {areas.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.area && errors.area}</FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{ gridColumn: "span 2" }}
                  error={!!touched.group && !!errors.group}
                >
                  <InputLabel>Age Group</InputLabel>
                  <Select
                    name="group"
                    value={values.group}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Group"
                  >
                    {group.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.group && errors.group}
                  </FormHelperText>
                </FormControl>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.price}
                  name="price"
                  error={!!touched.price && !!errors.price}
                  helperText={touched.price && errors.price}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Name of Instructor"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.instructor}
                  name="instructor"
                  error={!!touched.instructor && !!errors.instructor}
                  helperText={touched.instructor && errors.instructor}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Start Date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.start}
                  name="start"
                  error={!!touched.start && !!errors.start}
                  helperText={touched.start && errors.start}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="End Date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.end}
                  name="end"
                  error={!!touched.end && !!errors.end}
                  helperText={touched.end && errors.end}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              {/* RIGHT COLUMN */}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                height="fit-content"
                width="50%"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                {daysOfWeek.map((day) => (
                  <FormControlLabel
                    sx={{ margin: "0px", width: "100%", height: "2em" }}
                    key={day.label}
                    control={
                      <Checkbox
                        sx={{ padding: "2px" }}
                        name={day.label}
                        value={day.value} // Use day.value for the checkbox value
                        checked={values.days.includes(day.value)}
                        onChange={(event) =>
                          handleDayChange(event, setFieldValue)
                        }
                      />
                    }
                    label={day.label}
                  />
                ))}
                <Box
                  sx={{ display: "grid", gap: "30px", gridColumn: "span 4" }}
                >
                  {data.map((dayIndex) => (
                    <React.Fragment key={dayIndex}>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="time"
                        label={`Start Time (${daysOfWeek[dayIndex].label})`}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleTimeChange(dayIndex, "start", e.target.value)
                        }
                        value={timeRanges[dayIndex]?.start || ""}
                        name={`time[${dayIndex}].start`} // Update name for formik
                        error={
                          !!touched.time?.[dayIndex]?.start &&
                          !!errors.time?.[dayIndex]?.start
                        }
                        helperText={
                          touched.time?.[dayIndex]?.start &&
                          errors.time?.[dayIndex]?.start
                        }
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="time"
                        label={`End Time (${daysOfWeek[dayIndex].label})`}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          handleTimeChange(dayIndex, "end", e.target.value)
                        }
                        value={timeRanges[dayIndex]?.end || ""}
                        name={`time[${dayIndex}].end`} // Update name for formik
                        error={
                          !!touched.time?.[dayIndex]?.end &&
                          !!errors.time?.[dayIndex]?.end
                        }
                        helperText={
                          touched.time?.[dayIndex]?.end &&
                          errors.time?.[dayIndex]?.end
                        }
                        sx={{ gridColumn: "span 1" }}
                      />
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box>
              {/* BUTTON SUBMIT */}
              <Box display="flex" justifyContent="center" mt="20px">
                <Button type="submit" variant="contained">
                  Add Course
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Snackbar position
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CourseForm;
