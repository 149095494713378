import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/TopBar";
import Sidebar from "./scenes/global/SideBar";
import Team from "./scenes/team";
import Children from "./scenes/children";
import Courses from "./scenes/courses";
import Calendar from "./scenes/calendar";
import Logout from "./Authentication/logout";
import Events from "./scenes/events";
import AddEvent from "./scenes/addEvent";
import AddCourse from "./scenes/addCourse";
import { AuthProvider } from "./Authentication/AuthContext";
import Dashboard from "./scenes/dashboard";
import NavBar from "./scenes/global/NavBar";

function Admin() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
              <NavBar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="team" element={<Team />} />
                <Route path="children " element={<Children />} />
                <Route path="courses" element={<Courses />} />
                <Route path="events" element={<Events />} />
                <Route path="add-event" element={<AddEvent />} />
                <Route path="add-course" element={<AddCourse />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="logout" element={<Logout />} />
              </Routes>
            </main>
          </div>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default Admin;
