import { useState } from "react";
import { useAuth } from "../Authentication/AuthContext";

export const useModal = () => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [signupModalOpen, setSignupModalOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();

  const handleLoginCloseModal = () => {
    setLoginModalOpen(false);
  };

  const handleSignupCloseModal = () => {
    setSignupModalOpen(false);
  };

  const handleOpenSignupModal = () => {
    setSignupModalOpen(true);
  };

  const handleLoginOpenModal = () => {
    setLoginModalOpen(true);
  };

  const handleBookNow = (navigate, index) => {
    if (isAuthenticated) {
      if (index === 0) navigate("");
      else if (index === 1) navigate("/user/portal/faq");
      else logout();
    } else {
      if (index === 0) navigate("/faq");
      else if (index === 1) handleLoginOpenModal();
      else handleOpenSignupModal();
    }
  };

  return {
    loginModalOpen,
    signupModalOpen,
    handleLoginCloseModal,
    handleSignupCloseModal,
    handleOpenSignupModal,
    handleLoginOpenModal,
    handleBookNow,
  };
};
