import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
} from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../Authentication/AuthContext";

const initialValues = {
  username: "",
  password: "",
};

const userSchema = yup.object().shape({
  username: yup.string().required("Required field"),
  password: yup.string().required("Required field"),
});

function LoginModal({ modalOpen, handleCloseModal, handleOpenSignupModal }) {
  const { login } = useAuth();

  const handleFormSubmit = async (values, { setSubmitting }) => {
    // console.log(values);
    try {
      await login(values);
      // console.log("navigating");
      // navigate("/user/dashboard");
    } catch (err) {
      console.error("Login failed", err);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      sx={{
        minWidth: "100%",
        alignSelf: "center",
      }}
    >
      <DialogTitle textAlign="center">
        <img
          src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
          alt="logo"
          style={{
            width: "175px",
            height: "auto",
            padding: "20px",
            alignSelf: "center",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "4rem",
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ textAlign: "center", mb: "20px" }}>
            Enter your credentials
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                    sx={{
                      gridColumn: "span 12",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 12" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      backgroundColor: "#bc1a1a",
                      width: "47%",
                      padding: "15px 0px",
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Typography variant="p" sx={{ textAlign: "center" }}>
                    Don't have an account?{" "}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button
                    onClick={() => {
                      handleCloseModal();
                      handleOpenSignupModal();
                    }}
                  >
                    Sign Up
                  </Button>
                </Box>
                <Box display="flex" justifyContent="center" mt="10px"></Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LoginModal;
