import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginModal from "../home/loginModal";
import SignupModal from "../home/signupModal";
import { useModal } from "../../customHooks/useModal";
import { useAuth } from "../../Authentication/AuthContext";

const signedOut = ["FAQs", "Log in", "Sign up"];
const signedIn = ["Schedule", "FAQs", "Log out"];

const NavBar = () => {
  const navigate = useNavigate();
  const {
    loginModalOpen,
    signupModalOpen,
    handleLoginCloseModal,
    handleSignupCloseModal,
    handleOpenSignupModal,
    handleBookNow,
  } = useModal();

  const { isAuthenticated } = useAuth();

  const [buttons, setButtons] = useState(
    isAuthenticated ? signedIn : signedOut
  );

  useEffect(() => {
    setButtons(isAuthenticated ? signedIn : signedOut);
  }, [isAuthenticated]);

  return (
    <>
      <Box>
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: "96vw",
            boxSizing: "border-box",
          }}
        >
          {/* Logo */}
          <Grid item xs={6} md={4}>
            <Box>
              <a href="/home">
                <img
                  src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
                  alt="logo"
                  style={{ width: "140px", height: "auto", padding: "20px" }}
                />
              </a>
            </Box>
          </Grid>

          {/* Buttons */}
          <Grid item xs={6} md={8}>
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ overflowX: "auto" }}
            >
              {buttons.map((text, index) => (
                <Button
                  key={index}
                  onClick={() => handleBookNow(navigate, index)}
                  sx={{
                    backgroundColor: "#EF5340",
                    color: "white",
                    borderRadius: "30px",
                    margin: "10px",
                    width: "100px",
                    textTransform: "none",
                    transition: "transform 0.2s, backgroundColor 0.2s",
                    "&:hover": {
                      backgroundColor: "#F57A6A",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {text}
                </Button>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LoginModal
        modalOpen={loginModalOpen}
        handleCloseModal={handleLoginCloseModal}
        handleOpenSignupModal={handleOpenSignupModal}
      />
      <SignupModal
        modalOpen={signupModalOpen}
        handleCloseModal={handleSignupCloseModal}
      />
    </>
  );
};

export default NavBar;
