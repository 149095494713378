import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
} from "@mui/material";
import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { useAuth } from "../../Authentication/AuthContext";
import { useNavigate } from "react-router-dom";

import axios from "axios";
const initialValues = {
  firstname: "",
  lastname: "",
  password: "",
  password2: "",
  phoneNo: "",
  email: "",
};

const apiURL = process.env.REACT_APP_API_URL;
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const userSchema = yup.object().shape({
  firstname: yup.string().required("Required field"),
  lastname: yup.string().required("Required field"),
  password: yup
    .string()
    .required("Required field")
    .min(8, "Password must be at least 8 characters long"),
  password2: yup
    .string()
    .required("Required field")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  phoneNo: yup
    .string()
    .required("Required field")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: yup
    .string()
    .trim()
    .required("Required field")
    .email("Invalid email")
    .matches(emailRegex, "Invalid email"),
});

function SignupModal({ modalOpen, handleCloseModal }) {
  const { login } = useAuth();
  const navigate = useNavigate();
  const handleFormSubmit = async (values, { setSubmitting }) => {
    console.log(values);
    try {
      // Sending form data (values) to the backend
      const res = await axios.post(`${apiURL}/register-parent`, values, {
        withCredentials: true, // Include credentials (if necessary)
      });
      console.log("Server response:", res.data); // Log the actual response from the server
      await login(values.email, values.password);
    } catch (e) {
      console.error("Failed to insert user:", e); // Error handling
    } finally {
      setSubmitting(false); // Reset the form submitting state
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      sx={{
        minWidth: "100%",
        alignSelf: "center",
      }}
    >
      <DialogTitle textAlign="center">
        <img
          src="https://upstudio.africa/wp-content/uploads/2022/11/Logoinpill3-300x126.png"
          alt="logo"
          style={{
            width: "175px",
            height: "auto",
            padding: "20px",
            alignSelf: "center",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "4rem",
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ textAlign: "center", mb: "20px" }}>
            Sign up
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstname}
                    name="firstname"
                    error={!!touched.firstname && !!errors.firstname}
                    helperText={touched.firstname && errors.firstname}
                    sx={{
                      gridColumn: "span 6",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastname}
                    name="lastname"
                    error={!!touched.lastname && !!errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                    sx={{
                      gridColumn: "span 6",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{
                      gridColumn: "span 6",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Phone No."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNo}
                    name="phoneNo"
                    error={!!touched.phoneNo && !!errors.phoneNo}
                    helperText={touched.phoneNo && errors.phoneNo}
                    sx={{
                      gridColumn: "span 6",
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Set Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 12" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="password"
                    label="Repeat Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password2}
                    name="password2"
                    error={!!touched.password2 && !!errors.password2}
                    helperText={touched.password2 && errors.password2}
                    sx={{ gridColumn: "span 12" }}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      backgroundColor: "#bc1a1a",
                      width: "47%",
                      padding: "15px 0px",
                    }}
                  >
                    Create Account
                  </Button>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  mt="10px"
                  // sx={{ color: isDarkMode ? "white" : "black" }}
                ></Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default SignupModal;
