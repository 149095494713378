import { Box } from "@mui/material";
import NavBar from "../global/NavBar";
import LP from "../lp";
import * as React from "react";
import Footer from "../global/footer";
import FilterBar from "../tiles/filterBar";
import { useState, useEffect } from "react";
import Tiles from "../tiles/tiles";

const Home = () => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [programType, setProgramType] = useState("all");

  // Home
  return (
    // Home Page
    <Box display="flex" flexDirection="column" height="200vh">
      <Box width="100vw" height="100vh">
        {/* NavBar */}
        <Box height="10vh" pb="4vh" width="100vw">
          <NavBar />
        </Box>
        <Box
          height="86vh"
          padding="0"
          margin="0"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {/* Landing Page */}
          <LP />
        </Box>
      </Box>
      {/* TILES SECTION */}
      <Box
        id="next"
        // mt="20px"
        width={{ xs: "95%", sm: "80%", md: "80%" }}
        maxWidth="1200px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={{ xs: "2.5%", sm: "0 10%", md: "0 10%" }}
      >
        <FilterBar
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          selectedDays={selectedDays}
          setSelectedDays={setSelectedDays}
          programType={programType}
          setProgramType={setProgramType}
        />
        <Tiles
          selectedDays={selectedDays}
          selectedGroup={selectedGroup}
          programType={programType}
        />
      </Box>
      <Box mt="5em">
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
