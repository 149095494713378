import React from "react";
import { Box, Typography, Button } from "@mui/material";
import LoginModal from "../home/loginModal";
import SignupModal from "../home/signupModal";
import { useModal } from "../../customHooks/useModal";
import { useAuth } from "../../Authentication/AuthContext";

const EventTiles = ({ filteredEvents }) => {
  const {
    loginModalOpen,
    signupModalOpen,
    handleLoginCloseModal,
    handleSignupCloseModal,
    handleOpenSignupModal,
    handleLoginOpenModal,
  } = useModal();

  const { isAuthenticated } = useAuth();

  const handleBookNow = () => {
    if (!isAuthenticated) {
      handleLoginOpenModal();
    } else {
      // Logic for authenticated users to book now
    }
  };

  return (
    <>
      {filteredEvents.length ? (
        filteredEvents.map((event) => (
          <Box
            key={event.event_id}
            sx={{
              backgroundColor: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <Box position="relative">
              <img
                src={
                  event.area === "R"
                    ? `../../../assets/ep.jpg`
                    : event.area === "S"
                    ? `../../../assets/se.jpg`
                    : "../../../assets/se.jpg"
                }
                alt={event.area}
                style={{ width: "100%", height: "auto" }}
              />
              <Typography
                padding="0"
                margin="0"
                position="absolute"
                top="5%"
                right="5%"
                backgroundColor="white"
                p="5px"
              >
                TZS {event.price?.toLocaleString()}.00
              </Typography>
              <Typography
                padding="0"
                margin="0"
                position="absolute"
                bottom="2%"
                right="5%"
                backgroundColor="white"
                p="5px"
                sx={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                #event
              </Typography>
            </Box>
            <Box p={1}>
              <Typography variant="h5" noWrap color="#ef5340">
                {event.name}
              </Typography>
              <Typography variant="body2" noWrap fontWeight="bold">
                {event.date?.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}{" "}
                (
                {event.date?.toLocaleDateString("en-GB", {
                  weekday: "short",
                })}
                )
              </Typography>
              <Typography variant="body2" noWrap>
                {event.time} EAT
              </Typography>
              <Typography variant="body2" noWrap>
                {event.group}
                <span style={{ float: "right", color: "#ef5340" }}>
                  View Details
                </span>
              </Typography>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid #d3d3d3",
                }}
              />
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <img
                    src="https://upstudio.africa/wp-content/uploads/2022/11/favicon.png"
                    alt="img"
                    style={{
                      border: "2px solid #d3d3d3",
                      width: "40px",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: "#FFD33D",
                      borderRadius: "30px",
                      color: "black",
                      fontWeight: "normal",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FFC107",
                      },
                      margin: "2px",
                      textAlign: "right",
                      display: "block",
                      mx: "auto",
                      p: "0.5rem",
                    }}
                    onClick={handleBookNow}
                  >
                    Book Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Typography
          variant="body1"
          sx={{ gridColumn: "1 / -1", textAlign: "center" }}
        >
          No events available
        </Typography>
      )}
      <LoginModal
        modalOpen={loginModalOpen}
        handleCloseModal={handleLoginCloseModal}
        handleOpenSignupModal={handleOpenSignupModal}
      />
      <SignupModal
        modalOpen={signupModalOpen}
        handleCloseModal={handleSignupCloseModal}
      />
    </>
  );
};

export default EventTiles;
