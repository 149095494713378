import { useState, useEffect } from "react";
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const GetChildren = () => {
  const [data, setData] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    console.log("use effect called");

    const fetchData = async () => {
      try {
        console.log("try block");
        const res = await axios.get(`${apiURL}/children`, {
          withCredentials: true,
        });
        const formattedData = res.data.map((val) => ({
          ...val,
          date_of_birth: new Date(val.date_of_birth).toLocaleDateString(),
        }));
        console.log(formattedData.date_of_birth);
        setData(formattedData); // Set the fetched data
        setError(null); // Clear any previous errors
      } catch (e) {
        console.error(e);
        setError("Failed to fetch data"); // Set an error message
      } finally {
        setLoading(false); // Set loading to false when the request is complete
      }
    };

    fetchData();
  }, []);

  return { data, loading, error }; // Return data, loading, and error states
};

export default GetChildren;
