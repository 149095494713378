import React from "react";
import { Box, TextField, MenuItem, Button, Typography } from "@mui/material";

const FilterBar = ({
  selectedGroup,
  setSelectedGroup,
  selectedDays,
  setSelectedDays,
  programType,
  setProgramType,
}) => {
  const daysText = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const handleResetFilters = () => {
    setSelectedDays([]);
    setSelectedGroup("");
    setProgramType("all");
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      p={{ xs: "10px", sm: "30px" }}
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {/* Filter Programs By Title */}
      <Box
        display="flex"
        alignItems="center"
        p="10px"
        mx={{ xs: "10px", sm: "50px" }}
        flexBasis={{ xs: "100%", sm: "auto" }}
        textAlign="center"
      >
        <Typography variant="body1" color="#EF5340">
          Filter Programs by:
        </Typography>
      </Box>

      {/* Age Group Filter */}
      <Box
        display="flex"
        alignItems="center"
        p="10px"
        flexBasis={{ xs: "100%", sm: "auto" }}
        textAlign="center"
      >
        <Typography variant="body1">Age Group:</Typography>
        <TextField
          select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ ml: 2, flexGrow: 1, minWidth: "150px" }}
        >
          <MenuItem value="">All Groups</MenuItem>
          <MenuItem value="Level 1: 4-6 years">Level 1: 4-6 years</MenuItem>
          <MenuItem value="Level 2: 7-9 years">Level 2: 7-9 years</MenuItem>
          <MenuItem value="Level 3: 10-15 years">Level 3: 10-15 years</MenuItem>
        </TextField>
      </Box>

      {/* Program Type Filter */}
      <Box
        display="flex"
        alignItems="center"
        p="10px"
        flexBasis={{ xs: "100%", sm: "auto" }}
        textAlign="center"
      >
        <Typography variant="body1">Program Type:</Typography>
        <TextField
          select
          value={programType}
          onChange={(e) => setProgramType(e.target.value)}
          variant="outlined"
          size="small"
          sx={{ ml: 2, flexGrow: 1, minWidth: "150px" }}
        >
          <MenuItem value="all">All Types</MenuItem>
          <MenuItem value="event">Events</MenuItem>
          <MenuItem value="course">Courses</MenuItem>
        </TextField>
      </Box>

      {/* Day Filter */}
      <Box
        display="flex"
        alignItems="center"
        p="10px"
        flexBasis={{ xs: "100%", sm: "auto" }}
        textAlign="center"
      >
        <Typography variant="body1">Day:</Typography>
        <TextField
          select
          value={selectedDays}
          onChange={(e) =>
            programType === "events"
              ? setSelectedDays([])
              : setSelectedDays(e.target.value)
          }
          SelectProps={{ multiple: true }}
          variant="outlined"
          size="small"
          sx={{ ml: 2, flexGrow: 1, minWidth: "150px" }}
        >
          {daysText.map((day, index) => (
            <MenuItem key={index} value={day}>
              {day}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* Reset Filters Button */}
      <Box
        display="flex"
        alignItems="center"
        p="10px"
        flexBasis={{ xs: "100%", sm: "auto" }}
        justifyContent="center"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetFilters}
          sx={{
            borderRadius: "30px",
            backgroundColor: "#FFD33D",
            color: "black",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#FFC107",
            },
          }}
        >
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default FilterBar;
