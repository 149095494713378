import { Box, useTheme, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import GetChildren from "../../requests/getChildren";
import { useState, useEffect } from "react";

const Children = () => {
  const { data, loading, error } = GetChildren();
  console.log(data);
  return (
    <Box>
      <Box>
        <hr />
      </Box>
      <Box display="grid" gridTemplateColumns="auto auto" m="30px">
        <Box gridColumn="1">Name</Box>
        <Box gridColumn="2">FilterBar</Box>
      </Box>
    </Box>
  );
};

export default Children;
